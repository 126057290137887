const LOCAL_STORAGE_THEME_KEY = "THEME";
const PREFERS_COLOR_SCHEME_DARK_MEDIA_QUERY = "(prefers-color-scheme: dark)";

/**
 * All the possible states of Toggle
 */
enum ThemeSetting {
  // default state; the theme is automatically set given the `prefers-color-scheme` CSS media feature
  AUTOMATIC = "AUTOMATIC",
  // the user manually chose Light theme
  MANUAL_LIGHT = "MANUAL_LIGHT",
  // the user manually chose Dark theme
  MANUAL_DARK = "MANUAL_DARK",
}

// initialize everything necessary to switch themes
document.addEventListener("DOMContentLoaded", initTheme);

// initialize the Toggle Theme button
const toggleThemeButton: HTMLElement | null = document.getElementById("toggleThemeButton");
if (toggleThemeButton instanceof HTMLElement) {
  toggleThemeButton.addEventListener("click", toggleTheme);
}

// initialize `prefers-color-scheme` CSS media feature "change" listener
const darkModeMediaQuery: MediaQueryList = globalThis.matchMedia(PREFERS_COLOR_SCHEME_DARK_MEDIA_QUERY);
darkModeMediaQuery.addEventListener("change", handlePrefersColorSchemeMediaQueryToggle);

/**
 * Initializes a variable in Local Storage to handle automatic versus manual Light/Dark theme state.
 */
function initTheme(e: Event) {
  e.preventDefault();

  const theme: string | null = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);

  // if the key is already set to something, don't overwrite it
  if (theme !== null) {
    setTheme();
    return;
  }

  // default state should be automatic (aka defer to the `prefers-color-scheme` CSS media feature)
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.AUTOMATIC);
  setTheme();
}

/**
 * Handle the scenario that a user switches the `prefers-color-scheme` CSS media feature setting while on the page.
 */
function handlePrefersColorSchemeMediaQueryToggle(e: Event) {
  e.preventDefault();

  // the user changed their stance on `prefers-color-scheme`, set it back to AUTOMATIC
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.AUTOMATIC);
  setTheme();
}

/**
 * Switches the theme from Light theme to Dark theme, and vice versa.
 */
function toggleTheme(e: Event) {
  e.preventDefault();

  // get `prefers-color-scheme` CSS media feature setting
  const prefersDarkColorSchemeCssMediaFeature: boolean =
    globalThis.matchMedia(PREFERS_COLOR_SCHEME_DARK_MEDIA_QUERY).matches;

  // determine which theme to switch to given what the previous theme was
  const previousTheme: string | null = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
  switch (previousTheme) {
    case ThemeSetting.MANUAL_LIGHT: {
      // if current value is Manual Light theme, switch to Dark theme
      localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.MANUAL_DARK);

      // if the user set `prefers-color-scheme` CSS media feature to Dark theme, then change Manual Dark to Automatic
      if (prefersDarkColorSchemeCssMediaFeature) {
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.AUTOMATIC);
      }
      break;
    }
    case ThemeSetting.MANUAL_DARK: {
      // if current value is Manual Dark theme, switch to Light theme
      localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.MANUAL_LIGHT);

      // if the user set `prefers-color-scheme` CSS media feature to Light theme, then change Manual Light to Automatic
      if (!prefersDarkColorSchemeCssMediaFeature) {
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.AUTOMATIC);
      }
      break;
    }
    default: {
      // if the user set `prefers-color-scheme` CSS media feature to Dark theme, then switch to Manual Light theme
      if (prefersDarkColorSchemeCssMediaFeature) {
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.MANUAL_LIGHT);
      } else {
        // if the user set `prefers-color-scheme` CSS media feature to Light theme, then switch to Manual Dark theme
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, ThemeSetting.MANUAL_DARK);
      }
    }
  }

  setTheme();
}

/**
 * Calls the appropriate method to actually change the colors to the chosen theme.
 */
function setTheme() {
  // get `prefers-color-scheme` CSS media feature setting
  const prefersDarkColorSchemeCssMediaFeature: boolean =
    globalThis.matchMedia(PREFERS_COLOR_SCHEME_DARK_MEDIA_QUERY).matches;

  // set the appropriate theme
  const currentTheme: string | null = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
  switch (currentTheme) {
    case ThemeSetting.MANUAL_LIGHT: {
      setLightTheme();
      break;
    }
    case ThemeSetting.MANUAL_DARK: {
      setDarkTheme();
      break;
    }
    default: {
      // default to automatic theme (via `prefers-color-scheme` CSS media feature)
      if (prefersDarkColorSchemeCssMediaFeature) {
        setDarkTheme();
      } else {
        setLightTheme();
      }
      break;
    }
  }
}

/**
 * Sets the value of all CSS color variables to the Light theme variable values.
 */
function setLightTheme() {
  // Background
  document.documentElement.style.setProperty(
    "--color-background",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-background-light-theme",
    ),
  );

  // On Background
  document.documentElement.style.setProperty(
    "--color-on-background",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-background-light-theme",
    ),
  );

  // Surface
  document.documentElement.style.setProperty(
    "--color-surface",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-surface-light-theme",
    ),
  );

  // On Surface
  document.documentElement.style.setProperty(
    "--color-on-surface",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-surface-light-theme",
    ),
  );

  // Error
  document.documentElement.style.setProperty(
    "--color-error",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-error-light-theme",
    ),
  );

  // On Error
  document.documentElement.style.setProperty(
    "--color-on-error",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-error-light-theme",
    ),
  );

  // Primary
  document.documentElement.style.setProperty(
    "--color-primary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-light-theme",
    ),
  );

  // Primary Variant
  document.documentElement.style.setProperty(
    "--color-primary-variant",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-variant-light-theme",
    ),
  );

  // On Primary
  document.documentElement.style.setProperty(
    "--color-on-primary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-primary-light-theme",
    ),
  );

  // Secondary
  document.documentElement.style.setProperty(
    "--color-secondary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-secondary-light-theme",
    ),
  );

  // Secondary Variant
  document.documentElement.style.setProperty(
    "--color-secondary-variant",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-secondary-variant-light-theme",
    ),
  );

  // On Secondary
  document.documentElement.style.setProperty(
    "--color-on-secondary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-secondary-light-theme",
    ),
  );
}

/**
 * Sets the value of all CSS color variables to the Dark theme variable values.
 */
function setDarkTheme() {
  // Background
  document.documentElement.style.setProperty(
    "--color-background",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-background-dark-theme",
    ),
  );

  // On Background
  document.documentElement.style.setProperty(
    "--color-on-background",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-background-dark-theme",
    ),
  );

  // Surface
  document.documentElement.style.setProperty(
    "--color-surface",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-surface-dark-theme",
    ),
  );

  // On Surface
  document.documentElement.style.setProperty(
    "--color-on-surface",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-surface-dark-theme",
    ),
  );

  // Error
  document.documentElement.style.setProperty(
    "--color-error",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-error-dark-theme",
    ),
  );

  // On Error
  document.documentElement.style.setProperty(
    "--color-on-error",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-error-dark-theme",
    ),
  );

  // Primary
  document.documentElement.style.setProperty(
    "--color-primary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-dark-theme",
    ),
  );

  // Primary Variant
  document.documentElement.style.setProperty(
    "--color-primary-variant",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-variant-dark-theme",
    ),
  );

  // On Primary
  document.documentElement.style.setProperty(
    "--color-on-primary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-primary-dark-theme",
    ),
  );

  // Secondary
  document.documentElement.style.setProperty(
    "--color-secondary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-secondary-dark-theme",
    ),
  );

  // Secondary Variant
  document.documentElement.style.setProperty(
    "--color-secondary-variant",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-secondary-variant-dark-theme",
    ),
  );

  // On Secondary
  document.documentElement.style.setProperty(
    "--color-on-secondary",
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-on-secondary-dark-theme",
    ),
  );
}
